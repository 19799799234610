<template>
  <div class="login">
    <van-form>
      <van-notice-bar
        left-icon="volume-o"
        :text="$route.query.community_id ? '点击下方按钮，登录成功后，加入圈子。' : '点击下方按钮，登录成功后，才能获得奖励。'"
      />

      <p class="msg-title margin-top">恭喜您答题通过</p>
      <p class="msg-title">{{$route.query.community_id ? "请立即登录以加入圈子" : "请立即登录以获取现金奖励"}}</p>
      <p class="msg-tip margin-top-30 margin-bottom">问卷Id：{{$route.query.union_code ? $route.query.union_code : $route.query.cint_arid}}</p>
<!--      <p class="msg-tip margin-top&#45;&#45;70 margin-bottom" v-if="$route.query.community_id">您将同时加入私密圈子</p>-->

      <van-field v-if="notLogin" v-model="loginData.mobile" @input="inputChange" maxlength="11" type="number" center
        placeholder="请输入手机号" />
      <van-field v-if="notLogin" v-model="loginData.smsCode" @input="inputChange" maxlength="4" type="number" center
        clearable placeholder="请输入短信验证码">
        <template #button>
          <van-button size="small" native-type="button" @click="sendCode" :disabled="isDisabled" type="primary">
            {{sendCodeButtonText}}
          </van-button>
        </template>
      </van-field>
      <div v-if="notLogin" class="checkbox_content">
        <van-checkbox v-model="checked" @change="inputChange" checked-color="#3E639A" shape="square"></van-checkbox>
        <p>我已阅读并同意 <a href="/my/privacy?isUser=true">《服务协议》</a>和<a href="/my/privacy">《隐私申明》</a></p>
      </div>
      <van-button round block type="info" :class="{applyBtn:true ,active:isActive}" :disabled="iSubmit" @click="goHome"
        native-type="submit">{{submitText}}</van-button>
    </van-form>
  </div>
</template>

<script>
  import untils from "@/until/until";
  import wxLogin from '@/until/wxLogin.js'
  import {Toast} from "vant";
  import { NoticeBar } from 'vant';
  import Vue from "vue";
  Vue.use(NoticeBar)

  export default {
    name: "login",
    data() {
      return {
        submitText: "立即登录",
        notLogin: false,
        isSurveyCallback: false,
        checked: true,
        isDisabled: false,
        iSubmit: false,
        sendCodeButtonText: '获取验证码',
        timeNumber: 60,
        isActive: false,
        navigateToHomePage: false,
        timer: null,
        loginData: {
          mobile: '',
          smsCode: '',
        }
      }
    },
    mounted() {

    },
    async created() {
      if (untils.isWechat()) {
        const res = await wxLogin.getWechatAppId()
        await wxLogin.wxLogin(window.location.href, res.data.appId)
      }

      try {
        const userInfo = JSON.parse(localStorage.userInfo)
        // 未登录 或 未绑定手机号，都视为未登录
        this.notLogin = !localStorage.sessionKey || !userInfo || !userInfo.phone
      }catch (e) {
        this.notLogin = true
      }

      this.isActive = !this.notLogin
      if (this.$route.query.community_id) {
        this.submitText = "加入圈子"
      } else {
        this.submitText = "立即登录"
      }

      // 已登录时，直接提交
      if (!this.notLogin){
        this.$toast.loading({
          message: '提交中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });

        this.navigateToHomePage = true
        this.submitText = "提交结果"
        this.goHome()
      }
    },
    methods: {
      //跳转外部协议
      goAgreement() {
        this.$router.push('/my/privacy?isUser=true')
      },
      //发送验证码
      sendCode() {
        if (this.loginData.mobile.length == 0) {
          return false;
        }
        this.timer = setInterval(this.doLoop, 1000);
        this.isDisabled = true;
        this.$api.user.sendsms({
          "mobile": this.loginData.mobile
        }, res => {
          if (res.code == 200) {
            this.isDisabled = true;
          } else {
            clearInterval(this.timer);
            this.sendCodeButtonText = '获取验证码';
            this.isDisabled = false;
          }
        })
      },
      //调取登录接口
      login() {
        const query = this.$route.query
        //如果存在加入圈子
        if (query.project_id) {
          this.loginData.project_id = query.project_id
          this.loginData.channel_code = query.channel_code
          this.loginData.community_id = query.community_id
        }
        if (this.$route.query.code) {
          this.loginData.wxCode = this.$route.query.code
        }
        if (query.Surveytype || query.surveytype){
          this.loginData.surveytype = query.Surveytype || query.surveytype
        }
        // 若是 Cint 平台，取 cint_arid 参数
        if (this.$route.query.cint_arid){
          this.loginData.union_code = this.$route.query.cint_arid
        }else if (this.$route.query.union_code){
          // 其他平台，取 union_code 参数
          this.loginData.union_code = this.$route.query.union_code
        }
        this.$api.user.bindmobile(this.loginData, res => {
          if (res.code == 200 || res.code === 60005){
            localStorage.setItem('userInfo', JSON.stringify(res.data));
            localStorage.setItem('sessionKey', res.data.sessionKey);
            // 通过，但是非提示状态
            this.submitEvent("survey_end", (success, res)=>{
            })
            this.$router.push(this.getNaviSuccessPath())
          }else {
            this.$toast.clear()
            Toast.fail("提交异常，请重试")
          }
        })
      },
      getNaviSuccessPath(){
        let path = '/callback/survey?p_status=1&join_success=true&project_id=' + this.$route.query.project_id
        if (this.$route.query.community_id){
          path = path + '&community_id=' + this.$route.query.community_id
        }
        if (this.$route.query.union_code){
          path = path + '&union_code=' + this.$route.query.union_code
        }
        return path
      },
      submitEvent(event, callback){
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        let query = this.$route.query
        let activityId = query.project_id ? query.project_id : query.union_code
        if (query.cint_arid && !activityId){
          activityId = query.cint_arid
        }
        this.$api.my.submitEvent({
          "event": event,
          "activityId": activityId,
          "userId": UserInfo ? UserInfo.id : "",
          "nickname": UserInfo ? UserInfo.nickName : "",
          "status": query.p_status
        }, (success, res)=>{
          callback(success, res)
        })
      },
      navigateHome(){
        // 通过，但是非提示状态
        this.submitEvent("survey_end", (success, res)=>{
        })

        this.$toast.clear()
        // 公众号内回首页，其他地方引导关注公众号
        if (this.navigateToHomePage) {
          this.$toast.success("提交成功")
          this.$router.push('/')
        } else {
          this.$router.push(this.getNaviSuccessPath())
        }
      },
      goHome() {
        if (this.notLogin) {
          this.login()
        } else if (this.$route.query.project_id) {
          this.joinCircle(this.$route.query)
        } else {
          this.navigateHome()
        }
      },
      //加入圈子
      joinCircle(query) {
        this.$api.user.addSample({
          project_id: query.project_id,
          channel_code: query.channel_code,
          community_id: query.community_id,
          union_code: query.cint_arid ? query.cint_arid : query.union_code,
          surveytype: query.Surveytype || query.surveytype,
        }, res => {
          if (res.code === 200 || res.code === 60005) {
            this.navigateHome()
          }else {
            this.$toast.clear()
            Toast.fail("提交异常，请重试")
          }
        })
      },
      //倒计时开始
      doLoop() {
        this.timeNumber--;
        if (this.timeNumber > 0) {
          this.sendCodeButtonText = this.timeNumber + 'S';
        } else {
          clearInterval(this.timer); //清除js定时器
          this.isDisabled = false;
          this.sendCodeButtonText = '获取验证码';
          this.timeNumber = 60; //重置时间
        }
      },
      //输入框变化
      inputChange() {
        let mobileLength = this.loginData.mobile.length;
        let smsCodeLength = this.loginData.smsCode.length;
        let checked = this.checked;
        if (mobileLength == 11 && smsCodeLength == 4 && checked) {
          this.isTap(true);
        } else {
          this.isTap(false);
        }
      },
      isTap(off) {
        if (off) {
          this.isSubmit = false;
          this.isActive = true;
        } else {
          this.isSubmit = true;
          this.isActive = false;
        }
      }
    }
  }
</script>

<style scoped>
  .login {
    width: 100%;
    min-height: 1334px;
    background: #FFFFFF;
    padding-top: 20px;
  }

  .msg-title {
    text-align: center;
    font-size: 28px;
    width: 100%;
    color: #333333;
    font-weight: bold;
  }

  .msg-tip {
    text-align: center;
    font-size: 24px;
    width: 100%;
    color: #969799;
    margin-top: 6px;
  }

  .margin-top {
    margin-top: 100px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .margin-top--70{
      margin-top: -70px;
  }

  .margin-bottom {
    margin-bottom: 100px;
  }

  .van-cell {
    width: 100%;
  }

  .van-field {
    padding: 0 40px;
    margin: 0 auto;
    height: 116px;
    color: #333333;
    font-size: 32px;
  }

  .van-field__control {
    text-indent: 30px;
  }

  .van-button {
    background: #fff;
    color: #3E639A;
    font-size: 28px;
    cursor: pointer;
    border: none;
  }

  .van-checkbox {
    width: auto;
    margin-right: 15px;
  }

  .checkbox_content {
    font-size: 24px;
    display: flex;
    line-height: 40px;
    padding: 0 30px;
    margin: 46px auto 70px;
  }

  .checkbox_content a {
    color: #333333;
  }

  .applyBtn {
    margin: 0 auto;
    width: 594px;
    border: none;
    height: 88px;
    background: #dcdcdc;
    color: #fff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 32px;
  }

  .applyBtn.active {
    background: #3E639A;
  }
</style>
